import React from 'react'
import { CheckoutForm } from '@foundations-of-grace-pub/checkout'
import { StandardPage } from 'src/features/templates'
import SEO from 'src/components/seo'
import config from '../../../config'

const GatsbyCheckoutPage = () => (
  <StandardPage>
    <SEO title="Checkout" />
    <CheckoutForm stripeApiKey={config.stripeApiKey} />
  </StandardPage>
)

export default GatsbyCheckoutPage
